import React, { useState, useEffect } from "react";
import "./styles.css";

const calculateWinner = (squares) => {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      return squares[a];
    }
  }
  return null;
};

const Tictacktoe = ({ screenWidth, updateWinner }) => {
  const [history, setHistory] = useState([{ squares: Array(9).fill(null) }]);
  const [stepNumber, setStepNumber] = useState(0);
  const [isXNext, setIsXNext] = useState(true);
  const [status, setStatus] = useState("Win to get in!");

  const current = history[stepNumber];
  const winner = calculateWinner(current.squares);

  const resetGame = () => {
    setHistory([{ squares: Array(9).fill(null) }]);
    setStepNumber(0);
    setIsXNext(true);
  };

  const handleClick = (i) => {
    const historyCopy = history.slice(0, stepNumber + 1);
    const current = historyCopy[historyCopy.length - 1];
    const squares = current.squares.slice();
    if (winner || squares[i]) {
      return;
    }
    squares[i] = isXNext ? "X" : "O";
    setHistory(historyCopy.concat([{ squares: squares }]));
    setStepNumber(historyCopy.length);
    setIsXNext(!isXNext);
  };

  useEffect(() => {
    if (!isXNext && !winner) {
      const emptyIndices = [];
      current.squares.forEach((sq, idx) => {
        if (sq === null) {
          emptyIndices.push(idx);
        }
      });
      if (emptyIndices.length === 0) return;
      const randomIndex =
        emptyIndices[Math.floor(Math.random() * emptyIndices.length)];
      const timer = setTimeout(() => {
        handleClick(randomIndex);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isXNext, winner, current.squares]);

  const renderSquare = (i) => {
    return (
      <button className="tsquare" onClick={() => handleClick(i)}>
        {current.squares[i] || "."}
      </button>
    );
  };

  useEffect(() => {
    if (winner) {
      if (winner == "X") {
        updateWinner(true);
        setStatus("Congrats! You win!");
      } else {
        updateWinner(false);
        setStatus("Better luck next time!");
      }
    } else {
      setStatus(isXNext ? "Your turn" : "My turn");
    }
  }, [isXNext, winner]);

  return (
    <div className="game">
      <h4>Win to Get in!</h4>
      <div className="game-info">
        <b>{"TicTacToe?"}</b>
      </div>
      <div className="game-board">
        <div className="board-row">
          {renderSquare(0)}
          {renderSquare(1)}
          {renderSquare(2)}
        </div>
        <div className="board-row">
          {renderSquare(3)}
          {renderSquare(4)}
          {renderSquare(5)}
        </div>
        <div className="board-row">
          {renderSquare(6)}
          {renderSquare(7)}
          {renderSquare(8)}
        </div>
      </div>
      <div className="game-info">
        <div>{status}</div>
      </div>
      {stepNumber == 9 && !winner && (
        <div className="challenge-again">
          <button onClick={resetGame}>Challenge Again</button>
        </div>
      )}
    </div>
  );
};

export default Tictacktoe;

import React, { useState } from "react";
import "./styles.css";

function HomePage(props) {
  const { createBlock } = props;

  return (
    <div className="home-outer-cont">
      <script
        src="https://platform.linkedin.com/badges/js/profile.js"
        async
        defer
        type="text/javascript"
      ></script>
      <div className="inner-cont-left">
        <h1 className="name-title" onClick={createBlock}>
          Daniel JH Kim
        </h1>
      </div>
      <div className="inner-cont-right">
        {
          <>
            <div className="links-cont">
              <div className="home-subtitle">
                <h5>Project Links</h5>
              </div>
              <div className="project-links-cont">
                <ul>
                  <b>CoinStatData</b>
                  <div className="list-box">
                    <li onClick={createBlock}>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://github.com/coinStatData"
                      >
                        Github
                      </a>
                    </li>
                  </div>
                  <b>Passwordless Authentication</b>
                  <div className="list-box">
                    <li onClick={createBlock}>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://brokerportal.anthem.com/966d1659/passwordless.mp4"
                      >
                        Project Video Link
                      </a>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
            <div className="links-cont">
              <div className="home-subtitle">
                <h5>Social Links</h5>
              </div>
              <div className="social-list-box">
                <ul>
                  <div>
                    <a target="_blank" href="https://github.com/danieljhkim">
                      Github (personal)
                    </a>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/in/daniel-kim-io"
                    >
                      LinkedIn
                    </a>
                  </div>
                  <div>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://leetcode.com/danieljhkim"
                    >
                      Leetcode
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </>
        }
      </div>
    </div>
  );
}

export default HomePage;

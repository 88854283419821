import React, { useEffect } from "react";
import "./styles.css";

const Input = ({ setMessage, sendMessage, message, toggleJoin }) => {
  useEffect(() => {
    if (
      typeof message === "string" &&
      message.toLocaleLowerCase() === "login"
    ) {
      toggleJoin();
    }
  }, [message]);

  return (
    <form className="chat-input-form">
      <input
        className="input"
        type="text"
        placeholder="Type a message..."
        value={message}
        onChange={({ target: { value } }) => setMessage(value)}
        onKeyPress={(event) =>
          event.key === "Enter" ? sendMessage(event) : null
        }
      />
      <button className="sendButton" onClick={(e) => sendMessage(e)}>
        Send
      </button>
    </form>
  );
};

export default Input;

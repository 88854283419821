import React from "react";
import "./styles.css";

function Prii(props) {
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const makeItRain = (numb) => {
    const div = document.createElement("div");
    const a = document.createElement("a");
    a.classList.add("y-circle");
    a.classList.add("heart");
    a.style.animation = `y-circle ${getRandomInt(
      50
    )}s linear infinite alternate`;
    a.setAttribute("href", "https://youtu.be/dQw4w9WgXcQ");
    a.setAttribute("target", "_blank");
    div.classList.add("x-circle");
    div.style.animation = `x-circle ${getRandomInt(
      50
    )}s linear infinite alternate`;
    div.append(a);
    document.getElementById("circles-h").append(div);
  };

  return (
    <div id="prii-page" className="App">
      <div id="circles-h"></div>
      <div className="x2">
        <a
          rel="noreferrer"
          href="https://youtu.be/dQw4w9WgXcQ"
          target="_blank"
          className="heart y-circle"
        ></a>
      </div>
      <div className="prii-text-cont">
        <h1>
          To My Beloved Wife, <b>Priscila</b>
        </h1>
        <p>
          You are the light of my life and the reason for my smile. Your love
          and support mean the world to me. Thank you for being my partner, my
          confidant, and my best friend. I love you more than words can express.
        </p>
        <p>
          With all my love,
          <br />
          <br></br>
          <button onClick={makeItRain}>Daniel</button>
        </p>
      </div>
    </div>
  );
}

export default Prii;

import React, { useState } from "react";
import HomePage from "./pages/home/index.js";
import ChatV2 from "./components/chatV2";
import useScreenSize from "./hooks/useScreenSize";
import Tictacktoe from "./components/tiktacktoe/tiktacktoe.js";
import "./App.css";

function App() {
  const [showChat, setShowChat] = useState(false);
  const { screenWidth } = useScreenSize();
  const [isWinner, setIsWinner] = useState(false);
  const style =
    "background-color: darkblue; color: white; font-style: italic; border: 5px solid hotpink; font-size: 2em;";
  console.log("%cHello World!", style);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  };

  const toggleChat = () => {
    setShowChat(!showChat);
    if (!showChat) {
      makeItRain(50);
    }
  };

  const updateWinner = (hasWon) => {
    setIsWinner(hasWon);
  };

  const createBlock = () => {
    const div = document.createElement("div");
    const a = document.createElement("a");
    a.classList.add("y");
    a.classList.add("block");
    a.style.backgroundColor = `rgb(${getRandomInt(255)}, ${getRandomInt(
      255
    )}, ${getRandomInt(255)})`;
    a.style.animation = `y ${getRandomInt(50)}s linear infinite alternate`;
    a.setAttribute("href", "https://youtu.be/dQw4w9WgXcQ");
    a.setAttribute("target", "_blank");
    div.classList.add("x");
    div.style.animation = `x ${getRandomInt(50)}s linear infinite alternate`;
    div.append(a);
    document.getElementById("blocks").append(div);
  };

  const makeItRain2 = (numb) => {
    for (let i = 0; i < numb; i++) {
      createBlock();
    }
  };

  const makeItRain = (numb) => {
    for (let i = 0; i < numb; i++) {
      const div = document.createElement("div");
      const a = document.createElement("a");
      a.classList.add("y-circle");
      a.classList.add("heart");
      a.style.backgroundColor = `rgb(${getRandomInt(255)}, ${getRandomInt(
        255
      )}, ${getRandomInt(255)})`;
      a.style.animation = `y-circle ${getRandomInt(
        50
      )}s linear infinite alternate`;
      a.setAttribute("href", "https://youtu.be/dQw4w9WgXcQ");
      a.setAttribute("target", "_blank");
      div.classList.add("x-circle");
      div.style.animation = `x-circle ${getRandomInt(
        50
      )}s linear infinite alternate`;
      div.append(a);
      document.getElementById("circles").append(div);
    }
  };

  React.useEffect(() => {
    if (isWinner) {
      makeItRain2(1);
    }
  }, [isWinner]);

  return (
    <div id="main-app" className="App">
      <div id="blocks"></div>
      <div id="circles"></div>
      <div className="x">
        <a
          rel="noreferrer"
          href="https://youtu.be/dQw4w9WgXcQ"
          target="_blank"
          className="block y"
        ></a>
      </div>
      <div className="x2">
        <a
          rel="noreferrer"
          href="https://youtu.be/dQw4w9WgXcQ"
          target="_blank"
          className="block-2 y"
        ></a>
      </div>

      {isWinner ? (
        <>
          <HomePage createBlock={createBlock} />
          <ChatV2 screenWidth={screenWidth} />
        </>
      ) : (
        <Tictacktoe updateWinner={updateWinner} />
      )}
    </div>
  );
}

export default App;
